import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueHotjar from 'vue-hotjar-next'

import 'popper.js'
import 'bootstrap'

const app = createApp(App)

if (process.env.NODE_ENV === 'production') {
    app.use(VueHotjar, {
        id: '2699929', // Hotjar Site ID
        isProduction: true,
    })
}

app.use(router)
    .use(store)
    .mount('#app')
