import {createStore} from "vuex"

const store = createStore({
    state: {
        darkMode: false,
        isSidebarOpen: false,
    },
    getters: {
        darkMode: state => state.darkMode,
        isSidebarOpen: state => state.isSidebarOpen,
    },
    mutations: {
        SET_DARK_MODE(state, darkMode) {
            state.darkMode = darkMode
        },
        SET_IS_SIDEBAR_OPEN(state, isSidebarOpen) {
            state.isSidebarOpen = isSidebarOpen
        },
    },
    actions: {
        toggleClassSidebar({commit, state}) {
            commit("SET_IS_SIDEBAR_OPEN", !state.isSidebarOpen)
        }
    }
})

export default store
