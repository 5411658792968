<template>
  <div>
    <section class="section-hero sc-login">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6">
            <div class="card animate fadeIn">
              <div class="card-header text-md-center mt-3">
                <img src="@/assets/menuffy-logo.svg" class="img-fluid mb-3" width="80" height="80" alt="Menuffy Logo">
                <h1 class="typography-headline-lg fw-500">Sign In</h1>
                <p class="subtitle-1 text-muted my-2">
                  Access the Menuffy Partners panel.
                </p>
              </div>
              <div class="card-body">
                <div v-if="errorMessage" class="alert alert-danger">
                  {{ errorMessage }}
                </div>

                <div class="d-flex flex-wrap">
                  <button @click="onClickLogin('Google')"
                          :disabled="isLoading"
                          class="flex-fill btn btn-default-outline ripple ripple-primary py-3">
                    <svg width="24" height="24" class="me-2">
                      <g fill="none" fill-rule="evenodd">
                        <path
                            d="M20.66 12.7c0-.61-.05-1.19-.15-1.74H12.5v3.28h4.58a3.91 3.91 0 0 1-1.7 2.57v2.13h2.74a8.27 8.27 0 0 0 2.54-6.24z"
                            fill="#4285F4"></path>
                        <path
                            d="M12.5 21a8.1 8.1 0 0 0 5.63-2.06l-2.75-2.13a5.1 5.1 0 0 1-2.88.8 5.06 5.06 0 0 1-4.76-3.5H4.9v2.2A8.5 8.5 0 0 0 12.5 21z"
                            fill="#34A853"></path>
                        <path
                            d="M7.74 14.12a5.11 5.11 0 0 1 0-3.23v-2.2H4.9A8.49 8.49 0 0 0 4 12.5c0 1.37.33 2.67.9 3.82l2.84-2.2z"
                            fill="#FBBC05"></path>
                        <path
                            d="M12.5 7.38a4.6 4.6 0 0 1 3.25 1.27l2.44-2.44A8.17 8.17 0 0 0 12.5 4a8.5 8.5 0 0 0-7.6 4.68l2.84 2.2a5.06 5.06 0 0 1 4.76-3.5z"
                            fill="#EA4335"></path>
                      </g>
                    </svg>
                    <span class="d-none d-md-inline-block">Sign in with</span> Google
                  </button>
                </div>
<!--                <div class="d-flex flex-wrap">-->
<!--                  <button @click="onClickLogin('Facebook')"-->
<!--                          :disabled="isLoading"-->
<!--                          class="flex-fill btn btn-default-outline ripple ripple-primary py-3">-->
<!--                    <svg xmlns="http://www.w3.org/2000/svg" class="me-2" viewBox="0 0 24 24" width="24" height="24">-->
<!--                      <path fill="none" d="M0 0h24v24H0z"/>-->
<!--                      <path-->
<!--                          d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z"-->
<!--                          fill="rgba(23,120,242,1)"/>-->
<!--                    </svg>-->
<!--                    <span class="d-none d-md-inline-block">Sign in with</span> Facebook-->
<!--                  </button>-->
<!--                </div>-->
                <div class="d-flex flex-wrap">
                  <button @click="onClickLogin('Twitter')"
                          :disabled="isLoading"
                          class="flex-fill btn btn-default-outline ripple ripple-primary py-3">

                    <svg class="me-2" height="24" width="24" id="Layer_1" version="1.0" viewBox="0 0 32 32"
                         xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M31.993,6.077C30.816,6.6,29.552,6.953,28.223,7.11c1.355-0.812,2.396-2.098,2.887-3.63  c-1.269,0.751-2.673,1.299-4.168,1.592C25.744,3.797,24.038,3,22.149,3c-3.625,0-6.562,2.938-6.562,6.563  c0,0.514,0.057,1.016,0.169,1.496C10.301,10.785,5.465,8.172,2.227,4.201c-0.564,0.97-0.888,2.097-0.888,3.3  c0,2.278,1.159,4.286,2.919,5.464c-1.075-0.035-2.087-0.329-2.972-0.821c-0.001,0.027-0.001,0.056-0.001,0.082  c0,3.181,2.262,5.834,5.265,6.437c-0.55,0.149-1.13,0.23-1.729,0.23c-0.424,0-0.834-0.041-1.234-0.117  c0.834,2.606,3.259,4.504,6.13,4.558c-2.245,1.76-5.075,2.811-8.15,2.811c-0.53,0-1.053-0.031-1.566-0.092  C2.905,27.913,6.355,29,10.062,29c12.072,0,18.675-10.001,18.675-18.675c0-0.284-0.008-0.568-0.02-0.85  C30,8.55,31.112,7.395,31.993,6.077z" fill="#55ACEE"/>
                      <g/>
                      <g/>
                      <g/>
                      <g/>
                      <g/>
                      <g/></svg>
                    <span class="d-none d-md-inline-block">Sign in with</span> Twitter
                  </button>
                </div>
                <div class="d-flex flex-wrap">
                  <button @click="onClickLogin('Microsoft')"
                          :disabled="isLoading"
                          class="flex-fill btn btn-default-outline ripple ripple-primary py-3">
                    <svg class="me-2" height="24" style="enable-background:new 0 0 24 24;" version="1.1"
                         viewBox="0 0 512 512" width="24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"
                         xmlns:xlink="http://www.w3.org/1999/xlink"><g id="_x32_16-microsoft"><g><rect height="215.65" style="fill:#F25022;" width="215.648" x="30.905" y="30.904"/><rect
                        height="215.65" style="fill:#7FBA00;" width="215.648" x="265.446" y="30.904"/><rect
                        height="215.651" style="fill:#00A4EF;" width="215.648" x="30.905" y="265.444"/><rect
                        height="215.651" style="fill:#FFB900;" width="215.648" x="265.446" y="265.444"/></g></g>
                      <g id="Layer_1"/></svg>
                    <span class="d-none d-md-inline-block">Sign in with</span> Microsoft
                  </button>
                </div>

              </div>

              <div class="card-footer no-border text-center">
                <p class="subtitle-2 text-muted my-2">By signing up you agree to the Menuffy
                  <a href="https://menuffy.com/about/terms" class="subtitle-2 text-primary-contrast text-underline"
                     target="_blank" rel="noopener">Terms of Service</a> and
                  <a href="https://menuffy.com/about/privacy" class="subtitle-2 text-primary-contrast text-underline"
                     target="_blank" rel="noopener">Privacy Policy</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style>
h1 {
  letter-spacing: 0;
}
</style>

<script>
import {
  signInWithPopup,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  OAuthProvider
} from 'firebase/auth'
import {firebaseAuth} from "@/firebase"

export default {
  data() {
    return {
      isLoading: false,
      errorMessage: null,
    }
  },
  methods: {
    onClickLogin(byProvider) {
      let provider;

      if (byProvider === 'Facebook') {
        provider = new FacebookAuthProvider()
      } else if (byProvider === 'Google') {
        provider = new GoogleAuthProvider()
      } else if (byProvider === 'Twitter') {
        provider = new TwitterAuthProvider()
      } else if (byProvider === 'Microsoft') {
        provider = new OAuthProvider('microsoft.com')
      }

      this.isLoading = true
      this.errorMessage = null

      signInWithPopup(firebaseAuth, provider)
          .then(() => this.$router.replace({name: 'Home'}))
          .catch(err => {
            this.errorMessage = "The authentication process could not be completed."
            console.debug(err.message)
          })
          .finally(() => {
            this.isLoading = false
          })
    },
  }
}
</script>
