<template>
  <div>
    <section class="section-hero sc-login">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <lottie-animation
                path="animations/cancel-close.json"
                :loop="false"
                :autoPlay="true"
                :width="200"
                :height="200"
            />
            <h1 class="text-hero fw-500">Payment canceled</h1>
            <p class="text-subhero my-3">Sorry, we were unable to process your payment because it was canceled.</p>
            <router-link :to="{name: 'CompleteRegistration'}" class="btn btn-primary btn-micint-x ripple px-5 py-3">
              Go back to selecting a plan
              <i class="material-icons-outlined ms-2">&#xe5c8;</i>
            </router-link>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  components: {
    LottieAnimation
  },
}
</script>
