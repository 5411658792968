<template>
  <div>
    <section class="section-hero sc-login sc-plans">
      <div class="container">
        <div class="row mb-3">
          <div class="col-md-12 animate fadeIn">
            <i class="material-icons-outlined icon-wrap icon-wrap-primary mb-3">&#xe574;</i>
            <h1 class="fw-500 typography-headline-lg">Choose your plan</h1>
            <p class="paragraph">Simple and secure, you can cancel anytime.</p>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div v-if="errorMessage" class="alert alert-danger">
              {{ errorMessage }}
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <div class="card card-scale py-4"
                 :class="[selectedPriceId === monthlyPriceId ? 'border-primary' : 'border-default']">
              <div class="card-body d-flex justify-content-between">
                <div class="form-check">
                  <input v-model="selectedPriceId" :value="monthlyPriceId" type="radio" id="monthly"
                         class="form-check-input mt-2">
                  <label class="fw-bold form-check-label typography-headline-md" for="monthly">
                    Monthly
                  </label>
                </div>
                <div class="">
                  <span class="fw-500 typography-headline-md">$5 USD</span>
                  <span class="subtitle-1 text-muted"> / Month</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-3">
            <div class="card card-scale py-4"
                 :class="[selectedPriceId === monthlyPriceId ? 'border-default' : 'border-primary']">
              <div class="card-body d-flex justify-content-between">
                <div class="form-check">
                  <input v-model="selectedPriceId" :value="yearlyPriceId" type="radio" id="yearly"
                         class="form-check-input mt-2">
                  <label class="fw-bold form-check-label typography-headline-md" for="yearly">
                    Yearly
                  </label>
                </div>
                <div class="">
                  <span class="fw-500 typography-headline-md">$50 USD</span>
                  <span class="subtitle-1 text-success"> / Year (2 months FREE) 🎉</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-12 mt-3">
            <p class="subtitle-1">
              <span class="fw-bold">Your card won't be charged for 14 days.</span>

              If you cancel before then, your card won't be charged.

              You will be charged
              {{ selectedPriceId === monthlyPriceId ? '$5 USD' : '$50 USD' }}
              when your free trial ends and
              {{ selectedPriceId === monthlyPriceId ? '$5 USD' : '$50 USD' }}
              per
              {{ selectedPriceId === monthlyPriceId ? 'month' : 'year' }}
              afterwards.
            </p>
            <hr>

            <div class="d-flex justify-content-between">
              <p class="subtitle-1 mb-2 text-muted align-self-center">
                <img src="@/assets/img/protect.png" width="24" height="24" alt="Secure payments"/>
                Payments secure and powered by
                <a href="https://stripe.com/" class="subtitle-1 text-primary-contrast text-underline" target="_blank"
                   rel="noopener">Stripe</a>
              </p>
              <a href="https://stripe.com/" target="_blank" rel="noopener">
                <img src="@/assets/img/stripe-badge.svg" class="img-fluid" width="150" height="150" alt="Stripe badge">
              </a>
            </div>

          </div>
          <div class="col-md-12 mt-4">
            <button @click="openSubSession" :disabled="isLoading" class="btn btn-primary ripple py-3 px-5">
              <i class="material-icons-outlined me-2"
                 :class="[isLoading ? 'animate spin' : '']">{{ isLoading ? '&#xe863;' : '&#xe8cc;' }}</i>
              {{ isLoading ? 'Loading...' : 'GET 14 DAY TRIAL & PROCEED TO CHECKOUT' }}
            </button>

            <div>
              <button @click="logout" class="btn btn-link ripple ripple-primary mt-3">
                Sign out and go back
              </button>
            </div>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import settings from '@/settings'
import {getFunctions, httpsCallable} from 'firebase/functions'
import {signOut} from "firebase/auth";
import {firebaseAuth} from "@/firebase";

export default {
  data() {
    return {
      monthlyPriceId: settings.stripeMonthlyPriceId,
      yearlyPriceId: settings.stripeYearlyPriceId,
      selectedPriceId: settings.stripeYearlyPriceId,
      errorMessage: '',
      isLoading: false,
    }
  },
  methods: {
    openSubSession() {
      this.isLoading = true
      const functions = getFunctions()
      const createSubSession = httpsCallable(functions, 'createSubSession')
      createSubSession({priceId: this.selectedPriceId})
          .then(res => res.data)
          .then(data => window.location.href = data.redirect_url)
          .catch(err => {
            this.errorMessage = err.message
            this.isLoading = false
          })
    },
    logout() {
      signOut(firebaseAuth)
          .then(() => this.$router.replace({name: 'SignIn'}))
    }
  }
}
</script>
