import SubscriptionPaymentSuccess from "@/views/SubscriptionPaymentSuccess";
import SubscriptionPaymentCanceled from "@/views/SubscriptionPaymentCanceled";

export default [
    {
        path: '/subscription-payment-success',
        name: 'SubscriptionPaymentSuccess',
        component: SubscriptionPaymentSuccess,
        meta: {
            requiresAuth: true,
            allowWithoutPayment: true,
        }
    },
    {
        path: '/subscription-payment-canceled',
        name: 'SubscriptionPaymentCanceled',
        component: SubscriptionPaymentCanceled,
        meta: {
            requiresAuth: true,
            allowWithoutPayment: true,
        }
    }
]
