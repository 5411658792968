import SignIn from '@/views/SignIn'
import CompleteRegistration from '@/views/CompleteRegistration'

export default [
    {
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn,
        meta: {
            isGuestOnly: true
        }
    },
    {
        path: '/complete-registration',
        name: 'CompleteRegistration',
        component: CompleteRegistration,
        meta: {
            requiresAuth: true,
            allowWithoutPayment: true,
        }
    }
]
