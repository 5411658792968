export default [
    {
        path: '/restaurant-locations',
        name: 'Locations',
        component: () => import('../views/RestaurantLocations.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/restaurant-locations/create',
        name: 'CreateLocation',
        component: () => import('../views/CreateRestaurantLocation.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/complete-settings',
        name: 'CompleteSettings',
        component: () => import('../views/CompleteSettings.vue'),
        meta: {
            requiresAuth: true
        }
    },
]
