export default [
    {
        path: '/qr-code',
        name: 'QRCodeGenerator',
        component: () => import('../views/QrCodeGenerator.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/qr-code/print',
        name: 'QRCodePrint',
        component: () => import('../views/QRCodePrint.vue'),
        meta: {
            requiresAuth: true
        }
    },
]
