import {getApps, initializeApp} from "firebase/app"
import {getFirestore, doc, getDoc} from "firebase/firestore"
import {getAuth, onAuthStateChanged} from "firebase/auth"
import {getFunctions} from 'firebase/functions'

const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
}

const apps = getApps()
const firebaseApp = !apps.length ? initializeApp(firebaseConfig) : apps[0]
const db = getFirestore(firebaseApp)
const firebaseAuth = getAuth(firebaseApp)
const functions = getFunctions(firebaseApp)
const getMembership = userUUID => !userUUID ? null : getDoc(doc(db, `memberships/${userUUID}`)).then(snap => snap.data())
const restaurantInfoExists = userUUID => !userUUID ? null : getDoc(doc(db, `restaurants/${userUUID}`)).then(snap => snap.exists())
const getCurrentUser = () => new Promise((resolve, reject) => {
    const unsub = onAuthStateChanged(firebaseAuth, user => {
        unsub()
        resolve(user)
    }, reject)
})

export {firebaseApp, firebaseAuth, functions, getCurrentUser, getMembership, db, restaurantInfoExists}
