import {createRouter, createWebHistory} from 'vue-router'
import authenticationRoutes from "@/router/authentication"
import qrCodeRoutes from '@/router/qr-code'
import restaurantLocationsRoutes from '@/router/restaurant-locations'
import onboardingRoutes from '@/router/onboardings'
import PageNotFound from '@/views/PageNotFound'
import {getCurrentUser, getMembership, restaurantInfoExists} from '@/firebase'

const routes = [
    ...authenticationRoutes,
    ...restaurantLocationsRoutes,
    ...onboardingRoutes,
    ...qrCodeRoutes,
    {
        path: '/',
        redirect: '/sign-in'
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('../views/Home.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/menu',
        name: 'Menu',
        redirect: '/menu/categories',
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/menu/:tab',
        name: 'MenuTab',
        component: () => import('../views/Menu.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/account',
        name: 'Account',
        component: () => import('../views/Account.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/billing',
        name: 'Billing',
        component: () => import('../views/Billing.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/hours',
        name: 'Hours',
        component: () => import('../views/Hours.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/payments',
        name: 'Payments',
        component: () => import('../views/Payments.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('../views/Settings.vue'),
        meta: {
            requiresAuth: true
        }
    },

    {path: "/:pathMatch(.*)*", name: 'NotFound', component: PageNotFound},
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
    const isGuestOnly = to.matched.some(record => record.meta.isGuestOnly)
    const allowWithoutPayment = to.matched.some(record => record.meta.allowWithoutPayment)

    const currentUser = await getCurrentUser()
    const membership = await getMembership(currentUser?.uid)
    const restaurantExists = await restaurantInfoExists(currentUser?.uid)

    if (requiresAuth && !currentUser) {
        console.debug("Route middleware:", 1)
        next('/sign-in')
    } else if (currentUser && (!membership || membership?.status === 1 || membership?.status === 4) && !allowWithoutPayment) {
        console.debug("Route middleware:", 2)
        next('/complete-registration')
    } else if (currentUser && membership?.status > 1 && membership?.status < 4 && to.name === 'CompleteRegistration') {
        console.debug("Route middleware:", 3)
        next('/account')
    } else if (currentUser && membership?.status > 1 && membership?.status < 4 && !restaurantExists && to.name !== 'CompleteSettings') {
        console.debug("Route middleware:", 4)
        next('/complete-settings')
    } else if (currentUser && restaurantExists && to.name === 'CompleteSettings') {
        console.debug("Route middleware:", 5)
        next('/')
    } else if (isGuestOnly && currentUser) {
        console.debug("Route middleware:", 6)
        next('/account')
    } else {
        console.debug("Route middleware:", 7)
        next()
    }
})

export default router
